import React from 'react';

import Features from '../components/Features';
import Layout from '../components/Layout';
import SiteRoll from '../components/SiteRoll';

const IndexPageTemplate = () => {
  return (
    <Layout>
      <Features />
      <SiteRoll />
    </Layout>
  );
};

export default IndexPageTemplate;
