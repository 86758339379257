import React from 'react';

export default function Features() {
  return (
    <div className="feature">
      <h2>Top 1% eCommerce User Experience Curation.</h2>
      <p>
        Ecombae collects and highlights the top 1% eCommerce experience which is
        as beautiful as it is intuitive, this is a small step from MOTIF to
        inspire the next big commerceprenuers, designers, and developers to
        create amazing things.
      </p>
      {/* <ul>
        <li>
          <a href="#">Submit a Store</a>
        </li>
        <li>
          <a href="#">
            <svg
              width="49"
              height="56"
              viewBox="0 0 49 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M42.32 11.58C42.3007 11.4626 42.2432 11.3549 42.1564 11.2735C42.0696 11.1921 41.9584 11.1417 41.84 11.13L37.74 11.05C37.74 11.05 34.48 7.89003 34.16 7.56003C33.9906 7.44896 33.7981 7.37776 33.5972 7.35178C33.3963 7.3258 33.1921 7.34571 33 7.41003L31.36 7.92003C31.1474 7.25577 30.8728 6.61295 30.54 6.00003C29.37 3.77003 27.67 2.60003 25.62 2.60003H25.2L25 2.39003C24.5709 1.91916 24.0421 1.55002 23.4521 1.30955C22.8622 1.06907 22.226 0.963328 21.59 1.00003C18.94 1.07003 16.29 3.00003 14.15 6.39003C12.6507 8.7476 11.6312 11.3778 11.15 14.13L6 15.78C4.46 16.26 4.41 16.31 4.21 17.78C4 18.88 0 50 0 50L33.74 55.77L48.32 52.18L42.32 11.58ZM29.63 8.44003L27 9.25003C27.011 7.60617 26.7404 5.97256 26.2 4.42003C28.25 4.78003 29.2 7.08003 29.66 8.42003L29.63 8.44003ZM25.32 9.78003L19.68 11.52C20.1859 9.48271 21.162 7.59221 22.53 6.00003C23.0606 5.40616 23.7117 4.93228 24.44 4.61003C25.0907 6.25313 25.3904 8.01418 25.32 9.78003ZM21.7 2.78003C22.2638 2.7525 22.8224 2.89914 23.3 3.20003C22.5159 3.59981 21.8084 4.13466 21.21 4.78003C19.4087 6.86249 18.2 9.39058 17.71 12.1L13.09 13.58C14 9.31003 17.57 2.91003 21.67 2.78003H21.7Z"
                fill="#95BF46"
              />
              <path
                d="M41.88 11.13L37.78 11.05C37.78 11.05 34.52 7.88998 34.2 7.55998C34.073 7.44268 33.9118 7.36909 33.74 7.34998V55.78L48.32 52.19L42.32 11.58C42.3029 11.4684 42.2512 11.365 42.1723 11.2842C42.0934 11.2035 41.9912 11.1496 41.88 11.13Z"
                fill="#5F8E3E"
              />
              <path
                d="M25.64 18.65L23.94 25C22.63 24.4575 21.2162 24.2116 19.8 24.28C16.51 24.49 16.47 26.57 16.51 27.09C16.68 29.93 24.16 30.55 24.58 37.2C24.91 42.44 21.8 46.02 17.32 46.3C15.8127 46.4315 14.2946 46.2479 12.8622 45.7606C11.4297 45.2734 10.1145 44.4933 9 43.47L10.14 38.63C10.14 38.63 13.14 40.87 15.5 40.72C16.057 40.7069 16.5865 40.475 16.9739 40.0745C17.3613 39.674 17.5754 39.1371 17.57 38.58V38.46C17.33 34.76 11.25 34.98 10.86 28.89C10.54 23.78 13.91 18.57 21.34 18.1C22.7967 17.9347 24.2718 18.1233 25.64 18.65V18.65Z"
                fill="white"
              />
            </svg>{' '}
            Start a Shopify Store
          </a>
        </li>
        <li>
          <a href="#">Learn More</a>
        </li>
      </ul> */}
    </div>
  );
}
